.App {
  text-align: center;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  font-size: calc(5px + 1vmin);
  color: #ffffff; /* White color */
  position: relative;
  font-weight: bolder;
  z-index: 1;
}

.logo {
  width: 100px; /* Adjust size as needed */
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-bottom: 1rem;
}

.logo.visible {
  opacity: 1;
}

.robotic-text {
  /*font-family: 'Courier New', Courier, monospace;*/
  font-family: 'Orbitron', sans-serif, monospace;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  position: relative;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.cursor {
  animation: blink 0.5s infinite;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.05em -0.025em 0 #fc00ff,
      0.025em 0.05em 0 #fffc00;
  }
  14% {
    text-shadow: 0.05em 0 0 #00fffc, -0.05em -0.025em 0 #fc00ff,
      0.025em 0.05em 0 #fffc00;
  }
  15% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.025em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.025em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.025em 0.05em 0 #00fffc, 0.05em 0 0 #fc00ff,
      0 -0.05em 0 #fffc00;
  }
  99% {
    text-shadow: 0.025em 0.05em 0 #00fffc, 0.05em 0 0 #fc00ff,
      0 -0.05em 0 #fffc00;
  }
  100% {
    text-shadow: -0.025em 0 0 #00fffc, -0.025em -0.025em 0 #fc00ff,
      -0.025em -0.05em 0 #fffc00;
  }
}

.robotic-text:hover {
  animation: glitch 1s linear infinite;
}